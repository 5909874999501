import React from 'react';
import SanityImage from './SanityImage';
import { useImageSize } from '../hooks/useImageSize';
import { useNews } from '../hooks/useNews'
import { getDateString } from '../utils'
import { Link } from 'gatsby';
import { PortableText } from '../components/PortableText'
import { flattenBlogs } from '../utils';
import Meta from '../components/Meta'

const BlogPost = ({ _rawContent, title, _rawHero, _id, date, seo, next, previous, node, posts }) => {

	const imageSize = useImageSize(0.4359)
	let dateString
	if (date) dateString = getDateString(date)

	const { news } = useNews()
	const { blogPosts } = news

	let featuredBlogs;
	let isNext;
	let isPrevious;
	let nextBlog;
	let previousBlog;

	if (blogPosts) {
		featuredBlogs = flattenBlogs(blogPosts)
		const currentBlog = featuredBlogs[node?.slug?.current]
		const nextIndex = currentBlog?.index + 1
		const previousIndex = currentBlog?.index - 1

		if (nextIndex !== undefined) isNext = blogPosts[nextIndex] !== undefined
		if (previousIndex !== undefined) isPrevious = blogPosts[previousIndex] !== undefined

		if (isNext) nextBlog = blogPosts[nextIndex]
		if (isPrevious) previousBlog = blogPosts[previousIndex]
	}

	return (
		<>
			<Meta pageTitle={title || 'Blog'} props={seo} />
			<section
				id='blogPost'
				className='pt-[2%]'
			>
				<div className='pt-24 md:header-padding px-[4%] md:px-[6%]'>
					<div className='display-xs font-bold uppercase md:px-0 md:py-4'>
						<Link to='/'>Home /</Link>
						<Link to='/news'> News /</Link>
						<Link to='/blog'> Blog / </Link>
						<span className='body-xs font-light whitespace-nowrap'>{title}</span>
					</div>
					<div className='py-6 md:flex md:pt-0 md:pb-[5%]'>
						<div className='aspect-w-4 aspect-h-3 md:min-w-[50%] md:aspect-w-3 md:aspect-h-1 md:mr-[4%]'>
							<SanityImage
								loading='eager'
								maxWidth={imageSize}
								image={_rawHero}
								className='object-cover md:px-0'
							/>
						</div>
						<div className='px-[4%] md:px-0 md:mt-auto md:pb-[2%]'>
							<h1 className='h3 pt-5 pb-3 md:py-0 md:pb-7'>{title}</h1>
							{dateString && <p className='body'>{dateString}</p>}
						</div>
					</div>
					<div className='block md:hidden mb-6 border-b-4 border-black' />
					<PortableText className='blog rich-text' blocks={_rawContent} />
					<div className='flex pt-[5%] display-xs font-bold uppercase px-[4%] max-w-6xl mx-auto'>
						{isPrevious &&
							<Link
								className='self-start max-w-[45%]'
								to={`/blog/${previousBlog.slug?.current}`}
							>
								{`< ${previousBlog.title}`}
							</Link>
						}
						{isNext &&
							<Link
								className='ml-auto text-right max-w-[45%]'
								to={`/blog/${nextBlog.slug?.current}`}
							>
								{`${nextBlog.title} >`}
							</Link>
						}
					</div>
				</div>
			</section >
		</>
	);
}

export default BlogPost;