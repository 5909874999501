import React from 'react'
import { graphql } from 'gatsby'
import BlogPost from '../components/blogPost'
import Meta from '../components/Meta'

export const query = graphql`
  query BlogPostTemplateQuery($slug: String) {
    blog: sanityBlog(slug: {current: { eq: $slug }}) {
			_id
      slug {
				current
			}
			date
			_rawContent
    	title
			_rawHero
			hero {
				...Image
				alt
			}
			seo {
				title
				description
				image {
					asset {
						url
					}
				}
			}
    }
  }
`

const BlogPostTemplate = ({ data, pageContext }) => {
	const post = data && data.blog
	const allPostData = {
		...post,
		...pageContext
	}

	return (
		<>
		<Meta pageTitle={''} props={data?.blog?.seo} />
			<div className='fluid-container'>
				{post && <BlogPost {...allPostData} />}
			</div>
		</>
	)
}

export default BlogPostTemplate